import {ScormMetadata} from "./scorm/model";

export class UIInfo {
    allowView: boolean;
    hasMessage: boolean;
}
export interface LmsItem {
    id: number;
    title: string;
    description: string;
}

export interface LmsResource extends LmsItem {
    id: number
    courseId: number,
    catalogId: number,
    pathId: number,
    type: string
    metadata: ScormMetadata | any
}